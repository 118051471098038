import { Outlet, useLocation } from 'react-router-dom'
import {
  useMediaQuery,
  useDisclosure,
  Grid,
  GridItem,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  Flex,
} from '@chakra-ui/react'
import { HeaderMenu } from './menuHorizontal'
import { SideMenu } from './menuVertical/index'
import { useEffect, useState } from 'react'
import './css/layout.css'

export const Layout = () => {
  const { pathname } = useLocation()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isLinkMl, setIsLinkMl] = useState(false)

  const [lgPage] = useMediaQuery('(min-width: 1366px) and (min-width: 1367px)')
  const [mdPage] = useMediaQuery('(min-width: 768px) and (max-width: 1366px)');

  useEffect(() => {
    onClose()
    setIsLinkMl(
      pathname.toLocaleLowerCase() ===
        "/store/mercadolivrelink".toLocaleLowerCase()
        ? true
        : false
    );
  }, [onClose, pathname])

  return (
    <>
      <Grid
        templateColumns={lgPage ? '220px 1fr' : mdPage ? '70px 1fr' : '100%'}
        templateRows="1fr"
        className="bodyLayout"
        display={isLinkMl ? 'block' : 'grid'}
      >
        {!isLinkMl && (
          <GridItem className="vertical">
            <SideMenu isFullPage={lgPage} isMediumPage={mdPage} />
          </GridItem>
        )}

        <Drawer isOpen={isOpen} onClose={() => onClose()} placement={'left'}>
          <DrawerOverlay />
          <DrawerContent width="230px !important">
            <DrawerBody padding={0} overflow={'hidden'}>
              <SideMenu isFullPage={true} isMediumPage={false} />
            </DrawerBody>
          </DrawerContent>
        </Drawer>

        {/* Horizontal superior */}
        <GridItem colStart={lgPage || mdPage ? 2 : 1}>
          <Grid templateColumns="1fr" templateRows="50px">
            <GridItem
              className="horizontal"
              padding={!lgPage && !mdPage ? '0 16px' : '0 26px'}
            >
              <HeaderMenu
                handleToogleMenu={() => onOpen()}
                hasToogleMenu={!lgPage && !mdPage}
              />
            </GridItem>
          </Grid>

          {/* conteudo paginas */}
          <Flex
            className="bodyContent"
            w={lgPage ? '100%' : 'auto'}
            padding={!lgPage && !mdPage ? '0 16px' : '0 26px'}
          >
            <Outlet />
          </Flex>
        </GridItem>
      </Grid>
    </>
  )
}
