import React from "react"
import {
  Box,
  Button,
  Text,
  Wrap,
  WrapItem,
  Image,
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import './../css/integrations.css'
import { useAppsCard , ObjAppsProps } from "../hooks/useAppsCard";
import CardApp from "../AppDetails/components/CardApp"
import { getStoreAppByTag } from "src/utilities/storeApps";

export interface App {
  aplicativoId: number
  codigo: number
  descricao: string
  indAtivar: string
  indStatus: string
  nome: string
  tag: string
  valor: number
}

const bgColorActive = "rgba(16, 153, 114, 0.1)"; 
const bgColorInative = "rgba(102, 102, 102, 0.1)";
const borderColorInative = "#666666"
const borderColorActive = "#109972" 

export const Integrations = () => {
  const { objApps } = useAppsCard()
  const navigate = useNavigate()

  const customHeader = (app: string, urlIcon: string | undefined | null) => (
    <Box className="cardHeader">
      {urlIcon && (<Image src={urlIcon} h={"42px"} alt="workspace_img" />)}
      <Text fontSize={"14px"} fontWeight={"600"} fontFamily={"Inter"}>
          {app}
      </Text>
    </Box>
  );
   
  const customFooter = (status: boolean, tag: string) => (
    <Box className="cardFooterButton">
      <Button
        bg={status ? bgColorActive : bgColorInative}
        color={status ? borderColorActive : borderColorInative}
        border={`1px solid ${status ? borderColorActive : borderColorInative}`}
        size={"xs"}
        sx={{ cursor: "default", _hover: "unset" }}
      >
        {status ? "Ativo" : "Inativo"}
      </Button>
      <Text
        fontSize={"14px"}
        color={"#1155BB"}
        fontWeight={"600"}
        sx={{ cursor: "pointer" }}
        onClick={() => navigate(tag.toLocaleLowerCase())}
      >
        Ver detalhes
      </Text>
    </Box>
  );

  const customBody = (tag?: string) => {
    const data = getStoreAppByTag(tag)
    return(
    <Text
      fontSize={"14px"}
      fontWeight={"400"}
      fontFamily={"Inter"}
      color={"#475467"}
    >
      {data?.descricao ? data.descricao : 'Sem descricão'}
    </Text>
    )
  
  }

  return (
    <Box className="contentIntegrations">
      <Wrap spacing={"24px"} className="cards">
        {objApps &&
          objApps.map(
            (
              app: ObjAppsProps,
              index: React.Key 
            ) => {
              const appIcon = app?.iconMarketApp;
              if (app.name) {
                return (
                  <WrapItem key={index}>
                    <CardApp
                      header={customHeader(app.name, appIcon)}
                      body={customBody(app?.tag)}
                      footer={customFooter(app?.ativo,app?.tag)}
                    />
                  </WrapItem>
                );
              } else {
                return null;
              }
            }
          )}
      </Wrap>
    </Box>
  );
}