import { apiInstance } from 'src/services/api'

export const createdPasswordDefault = (cnpj: string): string => {
  const minutes = String(new Date().getMinutes()).padStart(2, '0')
  const seconds = String(new Date().getSeconds()).padStart(2, '0')

  const passwordDefault = `ls${cnpj.slice(0, 8)}${minutes}${seconds}`

  return passwordDefault
}

export const createdHashRememberPassword = async (
  email: string
): Promise<string> => {
  const user = {
    email: email,
  }

  const {
    data: { accessToken },
  } = await apiInstance(9008).post('/api/login', {
    login: 'consumoapi@londrisoft.com.br',
    password: 'ConsApi@2022%',
  })

  await apiInstance(8006).post('User/rememberPassword', user, {
    headers: {
      Authorization: 'Y8pUrDbc5zPgAwkLxWRbUrNkQATBmhYb',
    },
  })

  const {
    data: { tokenRememberPassword },
  } = await apiInstance(8006).get(`/User/Email/${email}`, {
    headers: {
      Authorization: `Y8pUrDbc5zPgAwkLxWRbUrNkQATBmhYb`,
    },
  })

  return tokenRememberPassword
}
