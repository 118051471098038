import { useCallback, useEffect, useRef, useState } from 'react'
import { useSession } from 'src/contexts/sessions'
import { apiInstance } from 'src/services/api'
import { FavoriteObj } from '../types/types'

type DataProps = {
  [key: string]: boolean
}

const defaultFavorites: FavoriteObj = {
  tag: {
    gestor: false,
    simplaz: false,
    analytics: false,
    analyticspro1: false,
    clubedocontador: false,
    importafacil: false,
    quod: false,
  },
}

export function useGetUser() {
  const { session } = useSession()
  const isMountedRef = useRef(false)
  const [data, setData] =
    useState<{ favorites: FavoriteObj; user: DataProps }>()
  const [isFetching, setIsFetching] = useState<boolean>(true)

  const getUsers = useCallback(async () => {
    if (session?.email) {
      const { data } = await apiInstance(8006).get(
        `User/Email/${session.email}`,
        {
          headers: {
            authorization: 'Y8pUrDbc5zPgAwkLxWRbUrNkQATBmhYb',
          },
        },
      )
      setData({
        favorites: data.indFavorito
          ? JSON.parse(data.indFavorito)
          : defaultFavorites,
        user: data,
      })
      setIsFetching(false)
    }
  }, [])

  useEffect(() => {
    isMountedRef.current = true
    setIsFetching(true)
    if (isMountedRef.current) {
      getUsers()
    }

    return () => {
      isMountedRef.current = false
    }
  }, [])
  return { dataUser: data, isFetching }
}
