import { useEffect, useState } from "react";
import { useGetApps } from "./../services/useGetApps";
import { IconsById } from "src/constants/hooks/iconsApps";

export interface ObjAppsProps {
  tag: string;
  name: string;
  ativo: any;
  iconMarketApp: string | null;
}

export function useAppsCard() {
  const { dataApps } = useGetApps();
  const [objApps, setObjApps] = useState<ObjAppsProps[] | undefined>([]);

  const returnApps = () => {
    if (dataApps) {
      const objCard: ObjAppsProps[] = [];

      Object.entries(dataApps).forEach(([key, value]) => {
        const iconKeyId = (value?.aplicativoId as any)
        objCard.push({
          tag: value?.tag,
          name: value?.nome,
          ativo: value?.indAtivar === "S" ? true : false,
          iconMarketApp: IconsById[iconKeyId]?.url ?? null,
        });
      });
      setObjApps(objCard);
    }
  };

  useEffect(() => {
    returnApps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataApps]);

  return { objApps };
}

