import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export function useGetUrl() {
  const { pathname } = useLocation()
  const [objApps, setObjApps] = useState<any>();

  const items = [
   
    {
      title: 'Analítico',
      path: '/analytics',      
    },
    {
      title: 'Analítico PRO',
      path: '/analytics-pro',
    },
    {
      title: 'Importa Fácil',
      path: '/importa-facil',
    },
    {
      title: 'Importa Fácil',
      path: '/importa-facil',
      breadCrumbs: [
        {
          title: 'Importados',
          route: '/importa-facil/selectHeaderTable',
        },
      ],
    },
    {
      title: 'Arquivos',
      path: '/files',
    },
    {
      title: 'Arquivos',
      path: '/files/relatorios',

    },
    {
      title: 'Arquivos',
      path: '/files',
      breadCrumbs: [
        {
          title: 'Enviados',
          route: '/files/enviados',
        },
      ],
    },
    {
      title: 'Arquivos',
      path: '/files',
      breadCrumbs: [
        {
          title: 'Impressão',
          route: '/files/impressao',
        },
      ],
    },
    {
      title: 'Meus Clientes',
      path: '/participants',
    },    
    {
      title: 'Meus Clientes',
      path: '/participants',
      breadCrumbs: [
        {
          title: 'Gerência',
          route: '/participants/management',
        },
      ],
    },
    {
      title: 'Loja de Apps',
      path: '/store/integrations',
    },
    {
      title: 'Loja de Apps',
      path: '/store/integrations',
      breadCrumbs: [
        {
          title: 'App',
          route: '/store/integrations/',
        },
      ],
    },
    {
      title: 'Ferramentas',
      path: '/store/tools',
    },
    {
      title: 'Loja de Apps',
      path: '/store/instructions',
    },
    {
      title: 'Minha conta',
      path: '/company-settings/geral',
      breadCrumbs: [
        {
          title: 'Configuração',
          route: '/company-settings/geral',
        },
      ],
    },
    {
      title: 'Minha conta',
      path: '/company-settings/geral',
      breadCrumbs: [
        {
          title: 'Certificado Digital',
          route: '/company-settings/certificate',
        },
      ],
    },
    {
      title: 'Minha conta',
      path: '/company-settings/geral',
      breadCrumbs: [
        {
          title: 'Usuários',
          route: '/company-settings/Users',
        },
      ],
    },
    {
      title: 'Minha conta',
      path: '/company-settings/geral',
      breadCrumbs: [
        {
          title: 'Usuários',
          route: '/company-settings/users/create',
        },
      ],
    },
    {
      title: 'Minha conta',
      path: '/company-settings/geral',
      breadCrumbs: [
        {
          title: 'Meus Serviços',
          route: '/company-settings/plan',
        },
      ],
    },
    {
      title: 'Minha conta',
      path: '/company-settings/geral',
      breadCrumbs: [
        {
          title: 'Pagamentos',
          route: '/company-settings/charge',
        },
      ],
    },
    {
      title: 'Minha conta',
      path: '/company-settings/geral',
      breadCrumbs: [
        {
          title: 'Meus Serviços',
          route: '/company-settings/plan',
        },
      ],
    }, 

  ];  

  /**
   * 
   * @param pathname inserir a rota para atalhos no breadcrumbs
   */
  const readUrl = (pathname: any) => {
    const subPaths = [
      '/importa-facil/selectHeaderTable',
      '/participants/management',
      '/files/enviados',
      '/files/impressao',
      '/store/integrations/',
      '/company-settings/geral',
      'company-settings/certificate',
      'company-settings/Users',
      'company-settings/charge',
      'company-settings/plan',
      '/company-settings/users/editing',
      '/company-settings/users/create'
    ];

    const foundPath = subPaths.find((path) => pathname.includes(path));

    let itemPath = null;
    if (foundPath) {
      itemPath = items.find(
        (f) =>
          f.path === foundPath ||
          (f.breadCrumbs &&
            f.breadCrumbs.some((crumb) => pathname.includes(crumb.route)))
      );
    } else {
      itemPath = items.find((f) => f.path === pathname && !f.breadCrumbs);

      if (!itemPath) {
        itemPath = null
      }
    }    
    setObjApps(itemPath);
  };

  useEffect(() => {
    readUrl(pathname);
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return { objApps };
}






