import { Flex, Text } from '@chakra-ui/react'

export const HeaderModal = () => {
  return (
    <Flex flexDir="column" mt="32px">
      <Text
        color="#303030"
        fontFamily="Sora"
        fontSize="20px"
        fontWeight={'700'}
      >
        Compartilhe o sucesso
      </Text>
      <Text
        color="#656C6F"
        fontFamily="Sora"
        fontSize="16px"
        fontWeight={'400'}
      >
        Indique um cliente para o ecossistema Londrisoft
      </Text>
    </Flex>
  )
}
