import CryptoJS from 'crypto-js'

const key = CryptoJS.enc.Utf8.parse('4512631236589784')
const iv = CryptoJS.enc.Utf8.parse('4512631236589784')

export function EncryptCrypto(cnpj: string, app: string, email: string) {
  const stringAmount = `|${cnpj}|${app}|${email}|`
  const encrypted = CryptoJS.AES.encrypt(
    CryptoJS.enc.Utf8.parse(stringAmount),
    key,
    {
      keySize: 128 / 8,
      iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    },
  )
  return encrypted.toString()
}
