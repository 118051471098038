import { Flex, Text, Input } from '@chakra-ui/react'

import { ReactComponent as UserMyService } from 'src/assets/icons/user-my-services.svg'
import { ReactComponent as IntegrationsMyService } from 'src/assets/icons/integrations-my-services.svg'
import { ReactComponent as StatusMyService } from 'src/assets/icons/status-my-services.svg'
import { useSession } from 'src/contexts'
import { formatting } from 'src/utilities/formattingToPattern'

interface IProps {
  financial: string
  users: any
  plan:
    | {
        id: number
        description: string
      }
    | undefined
  integrations: string[]
}

export const AboutAcoount = ({
  financial,
  integrations,
  plan,
  users,
}: IProps) => {
  const { session } = useSession()

  return (
    <Flex flexDir="column">
      <Text fontFamily={'sora'} fontWeight={'600'} fontSize="16px">
        Dados sobre sua conta
      </Text>
      <Flex align="center" h="70px" px="20px" mt="10px">
        {/* <Box textAlign="center" w="240px">
          <Text fontSize="xs" color="low.light">
            Plano Atual
          </Text>
          <Text fontSize="sm" mt="10px">
            {data.plan?.description}
          </Text>
        </Box> */}

        <Flex
          w="250px"
          h="65px"
          border="1px solid #E0E0E0"
          borderRadius={'4px'}
          alignItems={'center'}
        >
          <Flex ml="20px" mr="20px">
            <UserMyService />
          </Flex>
          <Flex flexDir="column">
            <Text
              color="#303030"
              fontFamily={'Sora'}
              fontSize="14px"
              fontWeight={'400'}
            >
              Acessos ativos
            </Text>
            <Text
              color="#303030"
              fontFamily={'Sora'}
              fontSize="16px"
              fontWeight={'700'}
              mt="5px"
            >
              {users}
            </Text>
          </Flex>
        </Flex>
        <Flex
          w="210px"
          h="65px"
          ml="24px"
          border="1px solid #E0E0E0"
          borderRadius={'4px'}
          alignItems={'center'}
        >
          <Flex ml="20px" mr="20px">
            <IntegrationsMyService />
          </Flex>
          <Flex flexDir="column">
            <Text
              color="#303030"
              fontFamily={'Sora'}
              fontSize="14px"
              fontWeight={'400'}
            >
              Integrações Ativas
            </Text>
            <Text
              color="#303030"
              fontFamily={'Sora'}
              fontSize="16px"
              fontWeight={'700'}
              mt="5px"
            >
              {integrations.length}
            </Text>
          </Flex>
        </Flex>

        <Flex
          w="204px"
          h="65px"
          ml="24px"
          border="1px solid #E0E0E0"
          borderRadius={'4px'}
          alignItems={'center'}
        >
          <Flex ml="20px" mr="20px">
            <StatusMyService />
          </Flex>
          <Flex flexDir="column">
            <Text
              color="#303030"
              fontFamily={'Sora'}
              fontSize="14px"
              fontWeight={'400'}
            >
              Status Financeiro
            </Text>
            <Text
              color="#303030"
              fontFamily={'Sora'}
              fontSize="16px"
              fontWeight={'700'}
              mt="5px"
            >
              {financial}
            </Text>
          </Flex>
        </Flex>
      </Flex>

      <Text mt="24px" fontFamily={'sora'} fontWeight={'600'} fontSize="16px">
        Contato Principal
      </Text>
      <Flex gap={8}>
        <Flex mt="8px" flexDir="column">
          <Text
            color="#BEBFBE"
            fontFamily={'sora'}
            fontWeight={'500'}
            fontSize="12px"
          >
            Responsável
          </Text>
          <Input
            value={session.company.responsible}
            bgColor="high.pure"
            borderRadius="4px"
            fontSize="sm"
            w="inherit"
            h="36px"
            mt="4px"
            color="#BEBFBE"
            fontFamily={'sora'}
            fontWeight={'400'}
            disabled
          />
        </Flex>

        <Flex mt="8px" flexDir="column">
          <Text
            color="#BEBFBE"
            fontFamily={'sora'}
            fontWeight={'500'}
            fontSize="12px"
          >
            E-mail do usuário principal
          </Text>
          <Input
            value={session.company.email}
            bgColor="high.pure"
            borderRadius="4px"
            fontSize="sm"
            w="270px"
            h="36px"
            mt="4px"
            color="#BEBFBE"
            fontFamily={'sora'}
            fontWeight={'400'}
            disabled
          />
        </Flex>

        <Flex mt="8px" flexDir="column">
          <Text
            color="#BEBFBE"
            fontFamily={'sora'}
            fontWeight={'500'}
            fontSize="12px"
          >
            Telefone principal
          </Text>
          <Input
            value={formatting({
              value: session.company.phone,
              pattern: 'phone',
            })}
            bgColor="high.pure"
            borderRadius="4px"
            fontSize="sm"
            w="inherit"
            h="36px"
            mt="4px"
            color="#BEBFBE"
            fontFamily={'sora'}
            fontWeight={'400'}
            disabled
          />
        </Flex>
      </Flex>
    </Flex>
  )
}
