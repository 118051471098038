import { Embed, Report, models, service } from 'powerbi-client'
import { PowerBIEmbed } from 'powerbi-client-react'

import { Box, Button, Flex, Text, useToast } from '@chakra-ui/react'
import { ArrowsOutSimple } from 'phosphor-react'
import { useCallback, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import {
  getAccessTokenBI,
  getCompanyReportData,
  postGravarLog,
} from 'src/services/api'
import './styles.css'

import { useSession } from 'src/contexts'
import { decryptedSimple, encryptedSimple } from 'src/utilities/hashing'
import { Header } from './components/Header'

interface AnalyticsBICookiesProps {
  id: string
  empresaId: number
  type: string
  embedUrl: string
  tokenType: number
  accessToken: string
}

export const ClubeContador = () => {
  const [report, setReport] = useState<Report>()
  const { session } = useSession()

  const [cookies, setCookie, removeCookie] = useCookies(['_abi'])
  const toast = useToast()

  const [sampleReportConfig, setReportConfig] =
    useState<models.IReportEmbedConfiguration>({
      type: 'report',
      embedUrl: undefined,
      tokenType: models.TokenType.Embed,
      accessToken: undefined,
      settings: undefined,
    })

  const sendLog = useCallback(() => {
    const log = {
      EmpresaCnpj: session.company.document,
      IdUsuario: session.id,
      NomeUsuario: session.name,
      Aplicativo: 'Clube Contador',
      Acao: 'Acesso ao Clube Contador',
      TipoAcao: 'Login',
      EndPoint: '',
    }

    postGravarLog(log)
  }, [])

  const [displayMessage, setMessage] = useState(
    `The report is bootstrapped. Click the Embed Report button to set the access token`,
  )

  const eventHandlersMap = new Map([
    [
      'loaded',
      function () {
        console.log('Report has loaded')
      },
    ],
    [
      'rendered',
      function () {
        console.log('Report has rendered')

        // Update display message
        setMessage('The report is rendered')
      },
    ],
    [
      'error',
      function (event?: service.ICustomEvent<any>) {
        if (event) {
          console.error(event.detail)
        }
      },
    ],
  ])

  const mockSignIn = async () => {
    if (session.plan.empresaId) {
      try {
        const companyReportData = await getCompanyReportData(
          session.company.document,
        )

        const reportEmbed = await getAccessTokenBI(
          session.plan.empresaId,
          companyReportData.company.powerBiGroupId,
          '57a062ff-2131-4dfd-9fae-e00729e99859',
        )

        if (reportEmbed?.message === 'OK') {
          setReportConfig({
            ...sampleReportConfig,
            type: reportEmbed.powerbi.type.toLocaleLowerCase(),
            id: reportEmbed.powerbi.embedReport[0].reportId,
            embedUrl: reportEmbed.powerbi.embedReport[0].embedUrl,
            accessToken: reportEmbed.powerbi.embedToken.token,
            tokenType: models.TokenType.Embed,
            settings: {
              panes: {
                filters: {
                  expanded: false,
                  visible: false,
                },
                pageNavigation: {
                  visible: false,
                },
              },
            },
          })

          const cookiesBI = encryptedSimple<AnalyticsBICookiesProps>({
            id: reportEmbed.powerbi.embedReport[0].reportId,
            empresaId: session.plan.empresaId,
            type: reportEmbed.powerbi.type,
            embedUrl: reportEmbed.powerbi.embedReport[0].embedUrl,
            accessToken: reportEmbed.powerbi.embedToken.token,
            tokenType: models.TokenType.Embed,
          })

          setCookie('_abi', JSON.stringify(cookiesBI), {
            path: '/',
            expires: new Date(reportEmbed.powerbi.embedToken.expiration),
          })
        } else {
          showError()
        }
      } catch (error) {
        console.log(error)
      }
    } else {
      showError()
    }
  }

  function showError() {
    toast({
      title: 'Um erro inesperado ocorreu!',
      description:
        'Tente novamente em instantes, caso o erro persista entre em contato com o departamento de atendimento!',
      duration: 3000,
      position: 'top-right',
      status: 'error',
      isClosable: true,
    })
  }

  useEffect(() => {
    const cookiesBI: AnalyticsBICookiesProps | undefined = cookies._abi
      ? decryptedSimple<AnalyticsBICookiesProps>(cookies._abi)
      : undefined

    if (cookiesBI && cookiesBI.empresaId === session.plan.empresaId) {
      setReportConfig({
        ...sampleReportConfig,
        type: cookiesBI.type.toLocaleLowerCase(),
        id: cookiesBI.id,
        embedUrl: cookiesBI.embedUrl,
        accessToken: cookiesBI.accessToken,
        tokenType: cookiesBI.tokenType,
        settings: {
          panes: {
            filters: {
              expanded: false,
              visible: false,
            },
            pageNavigation: {
              visible: false,
            },
          },
        },
      })
    } else {
      mockSignIn()
    }
  }, [cookies])

  useEffect(() => {
    sendLog()
  }, [sendLog])

  return (
    <Box width={'100%'} height={'98%'} background={'transparent'}>
      <Flex>
        <Flex
          width={'inherit'}
          justifyContent={'flex-end'}
          alignItems={'center'}
          paddingTop={'5px'}
          paddingEnd={'20px'}
        >
          <Button
            size={'1px'}
            variant={'unstyled'}
            display={'flex'}
            onClick={() => report?.fullscreen()}
            leftIcon={<ArrowsOutSimple size={20} color="#1155BB" />}
          >
            <Text fontSize={'12px'} color={'#1155BB'} marginTop={'3px'}>
              Full screen
            </Text>
          </Button>
        </Flex>
        <Header />
      </Flex>

      <PowerBIEmbed
        cssClassName="panel_bi"
        embedConfig={sampleReportConfig}
        eventHandlers={eventHandlersMap}
        getEmbeddedComponent={(embedObject: Embed) => {
          console.log(
            `Embedded object of type "${embedObject.embedtype}" received`,
          )
          setReport(embedObject as Report)
        }}
      />
    </Box>
  )
}
