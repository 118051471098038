import { Flex, Icon, IconButton } from '@chakra-ui/react'
import { ReactComponent as HMenu } from 'src/assets/icons/menu_icon.svg'
import { BreadCrumb } from './components/BreadCrumb'
import { PerfilUser } from './components/PerfilUser'

type HeaderProps = {
  handleToogleMenu: () => void
  hasToogleMenu: boolean
}

export const HeaderMenu = ({ handleToogleMenu, hasToogleMenu }:HeaderProps) => {

  return (
    <Flex
      height={'100%'}
      width={'100%'}
      justifyContent={'space-between'}
      zIndex={3}
    >
      <Flex
        width={'100%'}
        m="0 auto"
        align="center"
        justify="space-between"
      >
        <Flex align="center" gap={4}>
          {hasToogleMenu && (
            <IconButton
              variant="ghost"
              aria-label="menu"
              icon={<Icon as={HMenu} fontSize="24px" />}
              onClick={handleToogleMenu}
            />
          )}
          <BreadCrumb />
        </Flex>
        <Flex 
          gap="4"
          align="center"
          justifyContent={'flex-start'}
        >
          {/* <Notifications /> */}
          <PerfilUser />
        </Flex>
      </Flex>
    </Flex>
  )
}
