import { destroyCookie, parseCookies, setCookie } from 'nookies'
import { getTokenCookieGerenciadroPedido } from '../services/api'

export const appToken = 'lsmanagersales.token'
export const userToken = 'lsmanagersales.user'
const timeExpiredCookie = 60 * 60 * 24 // 24 hours

export const setCookieToken = async (token: string) => {
  try {
    if (getCookieToken()) {
      return getCookieToken()
    }

    const { data, status } = await getTokenCookieGerenciadroPedido(token)

    if (status > 199 && status < 300) {
      destroyCookieToken()
      setCookie(undefined, userToken, token, {
        maxAge: timeExpiredCookie,
      })

      setCookie(undefined, appToken, data.accessToken, {
        maxAge: timeExpiredCookie,
      })
    } else {
      throw new Error('Token inválido')
    }
  } catch (e) {
    console.log(e)
  }
}

export const getCookieToken = () => {
  const cookie = parseCookies()
  const tokenApp = cookie[appToken]
  const tokenUser = cookie[userToken]

  if (!tokenApp || !tokenUser) {
    return false
  }

  return { tokenApp, tokenUser }
}

export const destroyCookieToken = () => {
  destroyCookie(undefined, appToken)
  destroyCookie(undefined, userToken)
}
