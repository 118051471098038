import { Flex, Image, Button, Text } from '@chakra-ui/react'
import SuccessIndicationImage from 'src/assets/clube-contador/success-indication.svg'
import { X } from 'phosphor-react'

interface SuccessIndicationProps {
  setSuccessIndication: (value: boolean) => void
  onClose: () => void
}

export const SUccessIndiocation = ({
  onClose,
  setSuccessIndication,
}: SuccessIndicationProps) => {
  return (
    <Flex
      w="6xl"
      h="523px"
      position={'fixed'}
      backdropFilter={
        'blur(10px) saturate(180%) contrast(80%) brightness(120%) invert(10%)'
      }
      top={'60px'}
      borderRadius="6px"
      alignItems="center"
      justifyContent="center"
    >
      <Flex w="556px" h="170px" bg="#fff">
        <Flex>
          <Image src={SuccessIndicationImage} />
        </Flex>
        <Flex ml="25px" mt="20px" flexDirection={'column'}>
          <Flex
            w="325px"
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Text
              fontFamily={'inter'}
              fontWeight={'700'}
              fontSize="20px"
              color="rgba(0, 106, 255, 1)"
            >
              Agradecemos a indicação
            </Text>
            <Button
              bg="#fff"
              _hover={{ transform: 'rotate(-90deg)' }}
              onClick={() => {
                onClose()
                setSuccessIndication(false)
              }}
            >
              <X size={18} color="rgba(102, 112, 133, 1)" />
            </Button>
          </Flex>

          <Flex w="311px" mt="6px">
            <Text
              fontFamily={'inter'}
              fontWeight={'400'}
              fontSize="14px"
              color="rgba(71, 84, 103, 1)"
            >
              Entraremos em contato com a pessoa indicada, caso ela passe a a
              utilizar o nosso serviço, você receberá a recompensa no período
              que ela utilizar.
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
