import { Flex, Text, Image } from '@chakra-ui/react'
import { BsCheck } from 'react-icons/bs'
import { GrFormClose } from 'react-icons/gr'
import WppIcon from 'src/assets/icons/wpp-chager.svg'
import { useSession } from 'src/contexts'

export const FooterIndex = () => {
  const {
    session: {
      plan: { indfinanceiro },
    },
  } = useSession()
  return (
    <Flex mt="41px" flexDir={'row'} w="100%">
      <Flex flexDir={'column'}>
        <Flex>
          <Text fontSize={'16px'} fontWeight={'600'} fontFamily="inter">
            Status do cliente
          </Text>
          <Flex
            w="85px"
            h="22px"
            borderRadius="16px"
            bg={indfinanceiro === '0' ? '#ECFDF3' : '#FEF3F2'}
            color={indfinanceiro === '0' ? '#027A48' : '#B42318'}
            justifyContent={'center'}
            alignItems={'center'}
            fontFamily={'inter'}
            fontWeight={'500'}
            fontSize={'14px'}
            ml="12px"
          >
            {indfinanceiro === '0' ? <BsCheck /> : <GrFormClose />}
            {indfinanceiro === '0' ? 'Normal' : 'Pendente'}
          </Flex>
        </Flex>

        <Text fontSize={'13px'} fontWeight={'400'} fontFamily="inter">
          Fique em dia com os pagamentos e evite cancelamento.
        </Text>
      </Flex>

      <Flex ml="100px">
        <Image src={WppIcon} mr="21px" />
        <Flex flexDir="column">
          <Text fontSize={'16px'} fontWeight={'600'} fontFamily="inter">
            Dúvidas?
          </Text>
          <Text
            fontSize={'14px'}
            fontWeight={'400'}
            color="#475467"
            fontFamily="inter"
          >
            Entre em contato com nossa equipe amigável através do{' '}
            <a
              href="https://api.whatsapp.com/send?phone=554333159335"
              target={'_blank'}
              rel="noreferrer"
              style={{
                textDecoration: 'underline',
                color: '#52D45B',
                fontWeight: 'bold',
              }}
            >
              Whatsapp
            </a>{' '}
            com perguntas.
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}
