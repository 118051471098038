import {
  ApprovedOrderOrcamentoDigital,
  getClientPerId,
  getCompanyPerID,
  getOrderPerHash,
} from 'src/services/api';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { dataOrcamentoInterface } from './interface';
import dayjs from 'dayjs';
import { DataSeller } from './dataSeller';
import { DataClient } from './dataClient';
import { initialValueState } from './initialValueState';
import { Products } from './Products';
import { Faturamento } from './Faturamento';
import { Description } from './Description';
import { Question } from 'phosphor-react';
import {
  GetOrderHashOutput,
  PutOrderHashInput2,
} from 'src/schema/OrcamentoDigital';
import { GetClientByIDOutput } from '@/schema/Client';
import { GetCompanyByIDOutput } from '@/schema/Company';
import 'dayjs/locale/pt-br';

type dataOrcaType = {
  order: GetOrderHashOutput;
  client: GetClientByIDOutput;
  company: GetCompanyByIDOutput;
};

export const OrcamentoDigital = () => {
  // https://www.zeeintegra.com.br/orcamentodigital/6XS0HCSJH6XS0HCSJI

  const { code } = useParams();
  const navigate = useNavigate();

  const [isMobile] = useMediaQuery('(min-width: 200px) and (max-width: 968px)');
  const { isOpen, onOpen, onClose } = useDisclosure();

  const toast = useToast();

  const [loading, setLoading] = useState(false);
  const [obsClient, setObsClient] = useState('');

  const [dataOrca, setDataOrca] = useState<dataOrcaType>({
    order: {} as GetOrderHashOutput,
    client: {} as GetClientByIDOutput,
    company: {} as GetCompanyByIDOutput,
  });

  const [dataOrcamento, setDataOrcamento] =
    useState<dataOrcamentoInterface>(initialValueState);

  dayjs.locale('pt-br');

  const handleCalculateDiscountOrder = (order: dataOrcamentoInterface) => {
    const totalOrderPrice = order.orderPyamnetTerm[0].amount;

    const sum = order.products.reduce(
      (acc, item) => {
        acc.totalDesconto += item.quantity * item.unitaryValue - item.amount;

        acc.subTotal += item.quantity * item.unitaryValue;

        acc.total = totalOrderPrice;
        return acc;
      },
      {
        total: 0,
        totalDesconto: 0,
        subTotal: 0,
      }
    );

    return sum;
  };

  const getOrder = useCallback(async () => {
    try {
      setLoading(true);
      const [order] = await getOrderPerHash(code as string);
      const [client] = await getClientPerId(order.clientId);
      const company = await getCompanyPerID(order.companyId);

      const data = {
        nameCompany: company.FictitiousName,
        document: company.CnpjCpf,
        orderId: order.orderId,
        referenceId: order.referenceId,
        validade: order.dateExpirationLink,
        dateCreated: order.dateCreated,
        status: order.status === 0 ? 'Aguardando' : 'Aprovado',
        seller: {
          name: order.nameSeller,
          contact: order.phoneSeller,
        },
        client: {
          name: client.name,
          contact: client.phone,
          email: client.email,
          clientCostumer: client.clientCustomer,
        },
        totalOrder: {
          total: 0,
          totalDesconto: 0,
          subTotal: 0,
        },
        products: order.orderProduct,
        orderPyamnetTerm: order.orderPaymentTerm,
        obsInternal: order.obsInternal.split('\n'),
      };

      if (order.status === 1) {
        setObsClient(order.obsClient ?? '');
      }

      const totalOrderHandle = handleCalculateDiscountOrder(data);

      setDataOrcamento({ ...data, totalOrder: totalOrderHandle });

      setDataOrca({
        order: [order],
        client: [client],
        company,
      });

      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }, [code]);

  const handleConverterPayload = (
    dataOrca: dataOrcaType
  ): PutOrderHashInput2 => {
    const { order, client, company } = dataOrca;

    const payload = {
      orderId: order[0].orderId,
      clientId: client[0].clientId,
      clientCPF: client[0].cpfCnpj,
      clientReferenceId: null,
      observation: '',
      obsClient: obsClient.length > 3 ? obsClient : '...!',
      companyId: company.CompanyId,
      status: 1,
      userId: order[0].userId,
      referenceId: order[0].referenceId,
      origin: 'OrcamentoDigital',
      hash: order[0].hash,
      nameSeller: order[0].nameSeller,
      phoneSeller: order[0].phoneSeller,
      obsInternal: order[0].obsInternal,
      dateCreated: order[0].dateCreated,
      dateUpdated: order[0].dateUpdated,
      dateDeleted: order[0].dateDeleted,
      dateImport: order[0].dateImport,
      dateExpirationLink: order[0].dateExpirationLink,
      orderProduct: order[0].orderProduct,
      orderPaymentTerm: order[0].orderPaymentTerm,
    };
    return payload;
  };

  const handleAproovedOrder = async () => {
    try {
      const payload = handleConverterPayload(dataOrca);

      await ApprovedOrderOrcamentoDigital(payload, dataOrcamento.document);
      toast({
        title: 'Orçamento aprovado com sucesso',
        status: 'success',
        duration: 2000,
        isClosable: true,
        position: 'top',
        containerStyle: {
          fontFamily: 'Inter',
        },
      });
      navigate(0);
    } catch (e) {
      onClose();
      toast({
        title: 'Ocorreu um erro ao aprovar o orçamento',
        status: 'error',
        duration: 2000,
        isClosable: true,
        position: 'top',
        containerStyle: {
          fontFamily: 'Inter',
        },
      });
    }
  };

  useEffect(() => {
    getOrder();
  }, [getOrder]);

  return loading ? (
    <Flex w="full" h="100vh" justify="center" align="center">
      Carregando...
    </Flex>
  ) : (
    <Flex
      id="fullConteiner"
      w="full"
      flexDir={'column'}
      bg="#fff"
      h="100vh"
      overflowY={'auto'}
    >
      <Flex w="full" bg="#1155BB" h="43px"></Flex>
      <Flex
        w="full"
        bg="#f8f6fe"
        h="168px"
        borderBottom="1px solid rgba(96, 96, 96, .3);"
      >
        <Flex
          maxW={{ xl: '1024px', sm: 'full' }}
          w="full"
          mx="auto"
          p={{ '2xl': '0 64px 10px 64px;', sm: '0 24px 10px 24px' }}
        >
          <Flex mt="20px" mx="auto" w="full" flexDir="column" minW={'320px'}>
            <Text
              mb="20px"
              color="#6747b3"
              fontFamily={'inter'}
              fontWeight={'700'}
              fontSize={'26px'}
              textAlign={'center'}
            >
              ORÇAMENTO DIGITAL
            </Text>
            <Flex w="full" flexDir={'column'} p={'0px 10px 0px 10px'}>
              <Text
                fontFamily={'inter'}
                fontWeight={'700'}
                fontSize={'20px'}
                color="#303030"
              >
                {dataOrcamento?.nameCompany.toUpperCase() ?? ''}
              </Text>

              <Flex mt="10px" w="full" justifyContent={'space-between'}>
                <Flex>
                  <Flex
                    flexDir={'column'}
                    paddingRight={'10px'}
                    borderRight="1px solid #606060"
                  >
                    <Text
                      fontFamily={'inter'}
                      fontWeight={'400'}
                      fontSize={'14px'}
                      color="#606060"
                    >
                      Número:
                    </Text>
                    <Text
                      fontFamily={'inter'}
                      fontWeight={'700'}
                      fontSize={'14px'}
                      color="#6747b3"
                    >
                      {dataOrcamento?.referenceId ?? ''}
                    </Text>
                  </Flex>

                  <Flex flexDir={'column'} ml="10px">
                    <Text
                      fontFamily={'inter'}
                      fontWeight={'400'}
                      fontSize={'14px'}
                      color="#606060"
                    >
                      Validade:
                    </Text>
                    <Text
                      fontFamily={'inter'}
                      fontWeight={'700'}
                      fontSize={'14px'}
                      color="#6747b3"
                    >
                      {dayjs(dataOrcamento?.validade).format('DD/MM/YYYY') ??
                        ''}
                    </Text>
                  </Flex>
                </Flex>

                <Flex alignItems={'center'}>
                  <Text
                    fontFamily={'inter'}
                    fontWeight={'400'}
                    fontSize={'14px'}
                    color="#606060"
                    flexDir={'column'}
                    whiteSpace={'pre-line'}
                  >
                    {dayjs(dataOrcamento?.dateCreated).format('DD MMM YYYY')}
                  </Text>
                  <Text mr="5px" ml="5px">
                    |
                  </Text>
                  <Flex alignItems={'center'} flexDir={'column'}>
                    <Text
                      fontFamily={'inter'}
                      fontWeight={'400'}
                      fontSize={'14px'}
                      color="#606060"
                    >
                      Status:
                    </Text>

                    <Text
                      fontFamily={'inter'}
                      fontWeight={'700'}
                      fontSize={'14px'}
                      color={
                        dataOrcamento?.status === 'Aprovado'
                          ? '#00ff00'
                          : '#ff0000'
                      }
                      ml="5px"
                    >
                      {dataOrcamento?.status ?? ''}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <DataSeller
        name={dataOrcamento?.seller.name ?? ''}
        contact={dataOrcamento?.seller.contact ?? ''}
      />

      <DataClient client={dataOrcamento?.client} />

      <Products products={dataOrcamento?.products} />

      <Faturamento
        totalOrder={dataOrcamento?.totalOrder}
        obsPriceOrderTotal={dataOrcamento.orderPyamnetTerm[0].name}
      />

      <Description
        order={dataOrcamento}
        setObsClient={setObsClient}
        obsClient={obsClient}
      />

      <Flex
        p={{
          '2xl': '0 64px 10px 64px;',
          sm: '0 24px 10px 24px',
          base: '0 10px 10px 10px',
        }}
      >
        <Button
          mt="20px"
          mb="100px"
          mx={'auto'}
          w={{ xl: '300px', base: 'full', sm: 'full' }}
          onClick={onOpen}
          bg="#6747b3"
          color="#fff"
          fontFamily={'inter'}
          fontWeight={'700'}
          fontSize={'14px'}
          isDisabled={dataOrcamento.status === 'Aprovado'}
        >
          ACEITAR
        </Button>
      </Flex>

      {isMobile ? (
        <Drawer isOpen={isOpen} placement="bottom" onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent h="50%">
            <DrawerCloseButton />

            <DrawerBody>
              <Flex
                flexDir="column"
                justifyContent={'center'}
                alignItems={'center'}
                h="full"
              >
                <Question size={64} color="#333" />

                <Text
                  fontFamily={'inter'}
                  fontWeight={'700'}
                  fontSize={'20px'}
                  color="#333"
                >
                  Tem certeza ?
                </Text>
                <Text
                  fontFamily={'inter'}
                  fontWeight={'500'}
                  fontSize={'16px'}
                  color="#333"
                  mt="10px"
                  mb="10px"
                >
                  Você não podera mais interajir após a aprovação.
                </Text>
                <Flex gap="10px">
                  <Button colorScheme="green" onClick={handleAproovedOrder}>
                    SIM
                  </Button>
                  <Button colorScheme={'red'} onClick={onClose}>
                    NÃO
                  </Button>
                </Flex>
              </Flex>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      ) : (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody>
              <Flex
                flexDir="column"
                justifyContent={'center'}
                alignItems={'center'}
                h="full"
              >
                <Question size={64} color="#333" />

                <Text
                  fontFamily={'inter'}
                  fontWeight={'700'}
                  fontSize={'20px'}
                  color="#333"
                >
                  Tem certeza ?
                </Text>
                <Text
                  fontFamily={'inter'}
                  fontWeight={'500'}
                  fontSize={'16px'}
                  color="#333"
                  mt="10px"
                  mb="10px"
                >
                  Você não podera mais interajir após a aprovação.
                </Text>
                <Flex gap="10px">
                  <Button colorScheme="green" onClick={handleAproovedOrder}>
                    SIM
                  </Button>
                  <Button colorScheme={'red'} onClick={onClose}>
                    NÃO
                  </Button>
                </Flex>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </Flex>
  );
};
