import {
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Button,
  ModalContent,
  Text,
  Flex,
  Select,
  Input,
  Image,
  Box,
  Skeleton,
} from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'
import {
  getAplicativosWithDescription,
  sendEmailPloomes,
} from 'src/services/api'
import { GetAppLogoByTag } from '../getTagByLogo'

import { zodResolver } from '@hookform/resolvers/zod'
import { SubmitHandler, useForm } from 'react-hook-form'
import { z } from 'zod'

import { HeaderModal } from './Components/Header'
import { formatting } from 'src/utilities/formattingToPattern'
import { amountHTML } from '../HtmlPloomes'
import { useSession } from 'src/contexts'
import { Footer } from './Components/Footer'

import { SUccessIndiocation } from './Components/SuccessIndication'

interface IndicaParceiroProps {
  isOpen: boolean
  onClose: () => void
}

const formIndicarShema = z.object({
  nameIndicado: z.string().nonempty('Nome é obrigatório'),
  emailIndicado: z.string().email('Email inválido'),
  telefoneIndicado: z.string().nonempty('Telefone é obrigatório'),
})

type FormIndicar = z.infer<typeof formIndicarShema>

export const IndicaParceiro = ({ isOpen, onClose }: IndicaParceiroProps) => {
  const [apps, setApps] = useState<any[]>([])
  const [selectedApp, setSelectedApp] = useState<string>('')
  const [telefonePessoa, setTelefonePessoa] = useState<string>('')
  const [successIndication, setSuccessIndication] = useState<boolean>(false)

  const { session } = useSession()

  const appsDuplicated: any[] = [
    'SIMPLAZ',
    'SP_P4',
    'SP_P3',
    'SP_P2',
    'UNIQUE',
    'UN_L0',
    'UN_L1',
    'UN_L2',
    'UN_L3',
    'CL_CTD_P1',
    'CL_CTD_P2',
    'CL_CTD_P3',
    'CL_CTD_P4',
    'CL_CTD_P5',
    'GESTOR',
    'GE_L4',
    'GE_L3',
    'GE_L2',
    'GE_L1',
    'GE_N4',
    'GE_N3',
    'GE_N2',
    'GE_N1',
    'MOBV_',
  ]

  const filterExcludeAppsDuplicated = (apps: any) => {
    const appsFiltered = apps.filter((app: any) => {
      return !appsDuplicated.includes(app.tag)
    })
    return appsFiltered
  }

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormIndicar>({
    resolver: zodResolver(formIndicarShema),
  })

  const onSubmit: SubmitHandler<FormIndicar> = async (data) => {
    const htmlToSend = amountHTML({
      CNPJ_empresa: session.company.document,
      Razão_Social_empresa: session.company.name,
      Email_empresa: session.company.email,
      Produto_de_origem_empresa: 'Clube contador',
      Telefone_empresa: session.company.phone,

      nome_indicado: data.nameIndicado,
      email_indicado: data.emailIndicado,
      telefone_indicado: data.telefoneIndicado,
      produto_indicado: selectedApp,
    })

    const emailPloomes =
      window.location.origin.includes('localhost') ||
      window.location.origin.includes('alpha') ||
      window.location.origin.includes('192.168.0.221')
        ? 'isabela.londrisoft@gmail.com'
        : 'leadlondrisoft@gmail.com'

    try {
      await sendEmailPloomes({ Email: emailPloomes, Html: htmlToSend })
      setSuccessIndication(true)
    } catch (err) {
      console.log(err)
    }
  }

  const getApps = useCallback(async () => {
    const data = await getAplicativosWithDescription()
    const appsFilters: any[] = await filterExcludeAppsDuplicated(data)

    setSelectedApp(appsFilters[0].tag)

    setApps([
      ...appsFilters,
      {
        aplicativoId: 2,
        codigo: 1,
        nome: 'Simplaz',
        tag: 'SIMPLAZ',
      },
      {
        aplicativoId: 15,
        codigo: 2100,
        nome: 'Unique | Plano Light',
        tag: 'UNIQUE',
      },
      {
        aplicativoId: 26,
        codigo: 4100,
        nome: 'Clube do Contador - Iniciante',
        tag: 'CL_CTD_P1',
      },
      {
        aplicativoId: 11,
        codigo: 1201,
        nome: 'Gestor Nuvem | Plano NF-e ',
        tag: 'GESTOR',
      },
    ])
  }, [])

  useEffect(() => {
    getApps()
  }, [getApps])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody
          margin="0"
          p="0"
          boxShadow="0px 3px 6px 0px rgba(0, 0, 0, 0.1)"
        >
          <Flex>
            <Flex
              w="60%"
              h="100%"
              ml="24px"
              borderRight={'1px solid #E1E0E7'}
              flexDir="column"
            >
              <HeaderModal />
              <Flex w="90%" flexDir={'column'} mt="24px">
                <Text
                  color="#3D374B"
                  fontFamily="Sora"
                  fontSize="14px"
                  fontWeight={'400'}
                >
                  Serviço que deseja indicar:
                </Text>
                <Select
                  border="2px solid #CBD5E0"
                  mt="7px"
                  onChange={(e) => {
                    setSelectedApp(e.target.value)
                  }}
                >
                  {apps.map((app: any) => (
                    <option key={app.tag} value={app.tag}>
                      {app.nome}
                    </option>
                  ))}
                </Select>
              </Flex>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Flex w="90%" flexDir={'column'} mt="15px">
                  <Text
                    color="#3D374B"
                    fontFamily="Sora"
                    fontSize="14px"
                    fontWeight={'400'}
                  >
                    Nome da pessoa indicada:
                  </Text>
                  <Input
                    {...register('nameIndicado')}
                    border="2px solid #CBD5E0"
                    mt="7px"
                  />
                  <Text
                    fontFamily="inter"
                    fontSize={'12px'}
                    fontWeight="400"
                    color="red"
                  >
                    {errors.nameIndicado && errors.nameIndicado.message}
                  </Text>
                </Flex>
                <Flex w="90%" flexDir={'column'} mt="15px">
                  <Text
                    color="#3D374B"
                    fontFamily="Sora"
                    fontSize="14px"
                    fontWeight={'400'}
                  >
                    E-mail da pessoa indicada:
                  </Text>
                  <Input
                    {...register('emailIndicado')}
                    border="2px solid #CBD5E0"
                    mt="7px"
                  />
                  <Text
                    fontFamily="inter"
                    fontSize={'12px'}
                    fontWeight="400"
                    color="red"
                  >
                    {errors.emailIndicado && errors.emailIndicado.message}
                  </Text>
                </Flex>
                <Flex w="90%" flexDir={'column'} mt="15px">
                  <Text
                    color="#3D374B"
                    fontFamily="Sora"
                    fontSize="14px"
                    fontWeight={'400'}
                  >
                    Telefone da pessoa indicada:
                  </Text>
                  <Input
                    {...register('telefoneIndicado')}
                    border="2px solid #CBD5E0"
                    mt="7px"
                    onChange={(e) => setTelefonePessoa(e.target.value)}
                    value={formatting({
                      value: telefonePessoa,
                      pattern: 'phone',
                    })}
                  />
                  <Text
                    fontFamily="inter"
                    fontSize={'12px'}
                    fontWeight="400"
                    color="red"
                  >
                    {errors.telefoneIndicado && errors.telefoneIndicado.message}
                  </Text>
                </Flex>

                <Flex w="90%" justifyContent={'end'} mt="24px" mb="32px">
                  <Button
                    type="submit"
                    bg="#166DD7"
                    _hover={{ bg: '#166DD7', opacity: '0.8' }}
                    borderRadius="4px"
                    w="162px"
                    h="40px"
                    color="#fff"
                    fontFamily={'Sora'}
                    fontWeight={'600'}
                    fontSize={'14px'}
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                    loadingText="Indicando ... "
                  >
                    Indicar
                  </Button>
                </Flex>
              </form>
            </Flex>
            <Flex
              w="40%"
              h="523px"
              bg="#F8F7FA"
              flexDir="column"
              borderTopRightRadius={'10px'}
              borderBottomRightRadius={'10px'}
            >
              <Flex h="99%" w="100%">
                <Flex w="100%" h="100%">
                  <GetAppLogoByTag appTag={selectedApp} />
                </Flex>
              </Flex>
              <Footer apps={apps} selectedApp={selectedApp} />
            </Flex>
          </Flex>
          {successIndication && (
            <SUccessIndiocation
              onClose={onClose}
              setSuccessIndication={setSuccessIndication}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
