import {
  GestorProfiles,
  Profile,
  UniqueProfiles,
  WorkspaceProfiles,
} from '../../../../constants/profiles'
import { profileApps } from '../Create'

export const getProfileName = (
  appTag: string,
  profileApps: profileApps[],
): string => {
  const appsTags = profileApps.map((profile) => profile.Aplicativo)

  if (profileApps.length === 0) {
    return 'Adicionar Perfil'
  }

  if (
    (appTag.includes('SP_') || appTag.includes('SIMPLAZ')) &&
    appsTags.some((app) => app.includes('SIMPLAZ'))
  ) {
    const perfilId = profileApps.find((p) => p.Aplicativo === 'SIMPLAZ')
    const simplazProfileName = WorkspaceProfiles.find(
      (simplazProfile) => simplazProfile.id === perfilId?.Perfil.toString(),
    ) as Profile

    return simplazProfileName.name.replace('Usuário', 'Operacional') || ''
  }

  if (
    appsTags.some((app) => app.includes('GESTOR')) &&
    appTag.includes('GE_')
  ) {
    const perfilId = profileApps.find((p) => p.Aplicativo === 'GESTOR')
    const gestorProfileName = GestorProfiles.find(
      (gestorProfile) => gestorProfile.id === perfilId?.Perfil.toString(),
    ) as Profile

    return gestorProfileName.name || ''
  }

  if (
    appsTags.some((app) => app.includes('UNIQUE')) &&
    appTag.includes('UN_')
  ) {
    const perfilId = profileApps.find((p) => p.Aplicativo === 'UNIQUE')
    const uniqueProfileName = UniqueProfiles.find(
      (uniqueProfile) => uniqueProfile.id === perfilId?.Perfil.toString(),
    ) as Profile

    return uniqueProfileName.name || ''
  }

  return 'Não encontrado'
}
