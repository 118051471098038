import { useCallback, useEffect, useRef, useState } from "react";
import axios from 'axios';

interface AppInfo {
  name: string;
  version: string;
  buttonActive: string;
  textActive: string;
  buttonInactive?: string;
  textInactive?: string;
}

interface AppsData {
  [appName: string]: AppInfo;
}

export function useGetApps() {
  const isMountedRef = useRef(false);

  const [data, setData] = useState<AppsData | null>(null);
  const [isFetching, setIsFetching] = useState<boolean>(true);

  const URL_ASSETS = process.env.REACT_APP_ASSETS_URL
  const urlFtp = `${URL_ASSETS}/workspace/loja-apps/configuracoes-apps-mkt.json`

  const getApps = useCallback(async () => {

    if (urlFtp) {
      await axios
        .get(urlFtp)
        .then((response) => {
          setData(response.data);
        })
        .catch((error) => {
          console.error('Erro ao buscar os dados dos aplicativos:', error);
        })
        .finally(() => {
          setIsFetching(false);
        });
    } else setData(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    isMountedRef.current = true;
    setIsFetching(true);
    if (isMountedRef.current) {
      getApps();
    }

    return () => {
      isMountedRef.current = false;
      setData(null);
    };
  }, [getApps]);

  return { dataApps: data, isFetching };
}



