import { Box,Divider} from "@chakra-ui/react";
import { ReactNode } from "react";

interface CardAppProps {
  header?: string | ReactNode;
  body?: string | ReactNode;
  footer?: string | ReactNode;
}

export default function CardApp({ header, body, footer }: CardAppProps) {
  return (
    <Box className="cardBox">
      {header}
      <Box className="cardBody">{body}</Box>
      <Divider />
      <Box className="cardFooter">{footer}</Box>
    </Box>
  );
}