interface IconsUrlProps {
  gestor: {
    default: string
    hover: string
    favorite: string
  }
  unique: {
    default: string
    hover: string
    favorite: string
  }
  simplaz: {
    default: string
    hover: string
    favorite: string
  }
  importador: {
    default: string
    hover: string
    favorite: string
  }
  analitics: {
    default: string
    hover: string
    favorite: string
  }
  analiticsPro: {
    default: string
    hover: string
    favorite: string
  }
  quod: {
    default: string
    hover: string
    favorite: string
  }
  clube: {
    default: string
    hover: string
    favorite: string
  }
}

export interface MediaAppProps {
  icon: string;
  bannerAtivo: string;
  bannerInativo: string;
}

interface IconProps {
  url: string;
  name?: string;
}

export interface IconsToolsProps {
  anydesk: IconProps;
  sevenzip: IconProps;
  assistente: IconProps;
  lightshot: IconProps;
}

export const IconsUrlProduct: IconsUrlProps = {
  gestor: {
    default:
      'https://imagens-design.londrisoftservices.com.br/workspace/initial/images/ic_gestor_default.svg',
    hover:
      'https://imagens-design.londrisoftservices.com.br/workspace/initial/images/ic_gestor_hover.svg',
    favorite:
      'https://imagens-design.londrisoftservices.com.br/workspace/initial/images/ic_gestor_fav.svg',

  },
  unique: {
    default:
      'https://imagens-design.londrisoftservices.com.br/workspace/initial/images/ic_unique_default.svg',
    hover:
      'https://imagens-design.londrisoftservices.com.br/workspace/initial/images/ic_unique_hover.svg',
    favorite:
      'https://imagens-design.londrisoftservices.com.br/workspace/initial/images/ic_unique_fav.svg',
  },
  simplaz: {
    default:
      'https://imagens-design.londrisoftservices.com.br/workspace/initial/images/ic_simplaz_default.svg',
    hover:
      'https://imagens-design.londrisoftservices.com.br/workspace/initial/images/ic_simplaz_simplaz.svg',
    favorite:
      'https://imagens-design.londrisoftservices.com.br/workspace/initial/images/ic_simplaz_fav.svg',
  },
  importador: {
    default:
      'https://imagens-design.londrisoftservices.com.br/workspace/initial/images/ic_importafacil_default.svg',
    hover:
      'https://imagens-design.londrisoftservices.com.br/workspace/initial/images/ic_importafacil_hover.svg',
    favorite:
      'https://imagens-design.londrisoftservices.com.br/workspace/initial/images/ic_importafacil_fav.svg',
  },
  analiticsPro: {
    default:
      'https://imagens-design.londrisoftservices.com.br/workspace/initial/images/ic_analyticspro_default.svg',
    hover:
      'https://imagens-design.londrisoftservices.com.br/workspace/initial/images/ic_analyticspro_hover.svg',
    favorite:
      'https://imagens-design.londrisoftservices.com.br/workspace/initial/images/ic_analyticspro_fav.svg',
  },
  analitics: {
    default:
      'https://imagens-design.londrisoftservices.com.br/workspace/initial/images/ic_analytics_default.svg',
    hover:
      'https://imagens-design.londrisoftservices.com.br/workspace/initial/images/ic_analytics_hover.svg',
    favorite:
      'https://imagens-design.londrisoftservices.com.br/workspace/initial/images/ic_analytics_fav.svg',
  },
  quod: {
    default:
      'https://imagens-design.londrisoftservices.com.br/workspace/initial/images/ic_quod_default.svg',
    hover:
      'https://imagens-design.londrisoftservices.com.br/workspace/initial/images/ic_quod_hover.svg',
    favorite:
      'https://imagens-design.londrisoftservices.com.br/workspace/initial/images/ic_quod_hover.svg',
  },
  clube: {
    default:
      'https://imagens-design.londrisoftservices.com.br/workspace/initial/images/ic_clubedocontador_default.svg',
    hover:
      'https://imagens-design.londrisoftservices.com.br/workspace/initial/images/ic_clubedocontador_hover.svg',
    favorite:
      'https://imagens-design.londrisoftservices.com.br/workspace/initial/images/ic_clubedocontador_hover.svg',
  },
}

export const IconsById: any = {
  "2": {
    url: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/apps_icone_simplaz.svg",
    name: "Simplaz",
  },
  "3": {
    url: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/apps_icone_mercado-livre.svg",
    name: "Mercado Livre",
  },
  "4": {
    url: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/apps_icone_analytics-bi.svg",
    name: "Analytics BI",
  },
  "5": {
    url: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/apps_icone_analitcs-pro.svg",
    name: "Inteligência de Vendas",
  },
  "6": {
    url: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/apps_icone_analytics-bi.svg",
    name: "Inteligência Administrativa",
  },
  "7": {
    url: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/apps_icone_gestor.svg",
    name: "Gestor Local | Plano NF-e",
  },
  "8": {
    url: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/apps_icone_gestor.svg",
    name: "Gestor Local | Plano Básico",
  },
  "9": {
    url: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/apps_icone_gestor.svg",
    name: "Gestor Local | Plano Intermediário",
  },
  "10": {
    url: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/apps_icone_gestor.svg",
    name: "Gestor Local | Plano Avançado",
  },
  "11": {
    url: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/apps_icone_gestor.svg",
    name: "Gestor Nuvem | Plano NF-e",
  },
  "12": {
    url: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/apps_icone_gestor.svg",
    name: "Gestor Nuvem | Plano Básico",
  },
  "13": {
    url: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/apps_icone_gestor.svg",
    name: "Gestor Nuvem | Plano Intermediário",
  },
  "14": {
    url: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/apps_icone_gestor.svg",
    name: "Gestor Nuvem | Plano Avançado",
  },
  "15": {
    url: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/apps_icone_unique.svg",
    name: "Unique | Plano Light",
  },
  "16": {
    url: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/apps_icone_unique.svg",
    name: "Unique | Plano Plus",
  },
  "17": {
    url: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/apps_icone_unique.svg",
    name: "Unique | Plano Premium",
  },
  "18": {
    url: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/apps_icone_simplaz.svg",
    name: "Simplaz | Bronze",
  },
  "19": {
    url: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/apps_icone_simplaz.svg",
    name: "Simplaz | Silver",
  },
  "20": {
    url: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/apps_icone_simplaz.svg",
    name: "Simplaz | Gold",
  },
  "21": {
    url: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/apps_icone_importa-facil.svg",
    name: "Importa Fácil",
  },
  "22": {
    url: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/apps_icone_quod.svg",
    name: "Quod",
  },
  "25": {
    url: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/apps_icone_mobile.svg",
    name: "Mobile vendas",
  },
  "26": {
    url: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/apps_icone_clube-do-contador.svg",
    name: "Clube do Contador - Iniciante",
  },
  "27": {
    url: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/apps_icone_clube-do-contador.svg",
    name: "Clube do Contador - Prata",
  },
  "28": {
    url: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/apps_icone_clube-do-contador.svg",
    name: "Clube do Contador - Ouro",
  },
  "29": {
    url: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/apps_icone_clube-do-contador.svg",
    name: "Clube do Contador - Platina",
  },
  "30": {
    url: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/apps_icone_clube-do-contador.svg",
    name: "Clube do Contador - Diamante",
  },
  "31": {
    url: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/apps_icone_unique.svg",
    name: "Unique | Plano Empresarial",
  },
  "32": {
    url: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/apps_icone_simplaz.svg",
    name: "Simplaz | Bronze | Contador",
  },
  "33": {
    url: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/apps_icone_simplaz.svg",
    name: "Simplaz | Silver | Contador",
  },
  "34": {
    url: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/apps_icone_simplaz.svg",
    name: "Simplaz | Gold | Contador",
  },
  "35": {
    url: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/apps_icone_analytics-bi.svg",
    name: "Inteligência Vendas - Básico",
  },
  "36": {
    url: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/apps_icone_analytics-bi.svg",
    name: "Inteligência Vendas - Intermediário",
  },
  "37": {
    url: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/apps_icone_analytics-bi.svg",
    name: "Inteligência Vendas - Avançado",
  },
  "38": {
    url: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/apps_icone_analytics-bi.svg",
    name: "Inteligência Vendas - Customizável",
  },
};

export const IconsTools: IconsToolsProps = {
  anydesk: {
    url: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/apps_icone_anydesk.svg",
    name: "AnyDesk",
  },
  sevenzip: {
    url: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/apps_icone_7zip.svg",
    name: "7-Zip",
  },
  assistente: {
    url: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/apps_icone_assistente-ls.svg",
    name: "AssistenteLs",
  },
  lightshot: {
    url: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/apps_icone_light-shot.svg",
    name: "LightShot",
  },
};

/**
 * MediaAppsMarket - Um objeto que armazena os URLs dos ícones e banners de aplicativos do mercado.
 * 
 * Cada chave dentro do objeto representa um aplicativo específico, identificável por seu nome.
 * As propriedades de cada aplicativo são:
 * 
 * @property {string} icon - URL do ícone do aplicativo.
 * @property {string} bannerAtivo - URL do banner ativo do aplicativo.
 * @property {string} bannerInativo - URL do banner inativo do aplicativo.
 */
export const MediaAppsMarket:Record<string, MediaAppProps> = {
  analyticsbi:{
    icon: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/icone_analytics-bi.png",
    bannerAtivo: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/banner_bi_ATIVO.png",
    bannerInativo : "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/banner_bi_1644x200px.png",
  },
  mercadolivre:{
    icon: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/icone_ml.png",
    bannerAtivo: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/banner_ml_ATIVO.png",
    bannerInativo : "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/banner_ml_1644x200px.png",
  },
  simplaz: {
    icon: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/icone_simplaz.png",
    bannerAtivo: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/banner_simplaz_ATIVO.png",
    bannerInativo : "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/banner_simplaz_1644x200px.png",
  },
  analyticspro:{
    icon: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/icone_anl-pro.png",
    bannerAtivo: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/banner_anl-pro_ATIVO.png",
    bannerInativo : "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/banner_anl-pro_1644x200px.png",
  },
  importafacil:{
    icon: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/icone_importa-facil.png",
    bannerAtivo: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/banner_importa-facil_ATIVO.png",
    bannerInativo : "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/banner_importa-facil_1644x200px.png",
  },
  gestor:{
    icon: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/icone_gestor.png",
    bannerAtivo: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/banner_gestor_ATIVO.png",
    bannerInativo : "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/banner_gestor_1644x200px.png",
  },
  unique:{
    icon: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/icone_unique.png",
    bannerAtivo: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/banner_unique_ATIVO.png",
    bannerInativo : "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/banner_unique_1644x200px.png",
  },
  mbv:{
    icon: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/icone_mobile.png",
    bannerAtivo: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/banner_mobile_ATIVO.png",
    bannerInativo : "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/banner_mobile_1644x200px.png",
  },
  bime:{
    icon: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/icone_bime.png",
    bannerAtivo: "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/banner_bime_ATIVO.png",
    bannerInativo : "https://imagens-design.londrisoftservices.com.br/workspace/loja-apps/banner_bime_1644x200px.png",
  },
 
  
 
 
  

};
